import { Link } from 'react-router-dom';
import { scrollToHash } from '../../../../utils/hashHook';

export const GoToTopLink = () => {
    return (
        <Link tabIndex={0} to="#toppen" className="go-to-top" onClick={() => scrollToHash('toppen')}>
            <span className="sr-only">Til toppen</span>
        </Link>
    );
};

export default GoToTopLink;
