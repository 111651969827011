import { State, Taxonomy, useContentDeliveryAPI, useEpiserver, useIContentRepository } from '@episerver/spa-core';
import { getLocalizationText, General, OldDomstol } from '../../../../utils/getLocalizationText';
import { SubMenuDto } from '../../../../Models/Interfaces/ApiResponse/TransportBlockApiResponse';
import { OldDomainPageType } from '../../../../Models/Content/OldDomainPageData';
import Website from '@episerver/spa-core/dist/Models/Website';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import axios from 'axios';
import {
    english_language_full_name,
    samegiella_language_full_name,
    norwegian_language_full_name,
} from '../../../../Models/Constants/LanguageTypes';
import { Link } from 'react-router-dom';

export type MainMenuProps = Partial<State.CmsState> & {
    dispatch?: (action: State.CmsStateAction) => void;
    domainPage: OldDomainPageType;
    currentPage: Taxonomy.IContent;
    menu: SubMenuDto[];
};

export const MainMenu = (props: MainMenuProps) => {
    const searchMobileFieldRef = useRef();
    const api = useContentDeliveryAPI();
    const navigate = useNavigate();
    const ctx = useEpiserver();
    const repo = useIContentRepository();
    const [currentAnchestors, setCurrentAnchestors] = useState<string[]>([]);
    const [searchPageUrl, setSearchPageUrl] = useState<string>('#');

    const handleButtonPress = (e: React.MouseEvent<HTMLInputElement>) => {
        e.preventDefault();
        const searchUrl = getSearchUrl();
        if (searchUrl) {
            redirectToSearchPage(searchUrl);
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement> & React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key == 'Enter') {
            e.preventDefault();
            const searchUrl = getSearchUrl();
            if (searchUrl) {
                redirectToSearchPage(searchUrl);
            }
        }
    };

    function redirectToSearchPage(newSearchUrl: string) {
        const currentPath = location.pathname.split('?');
        const searchPage = ctx.getSpaRoute(newSearchUrl).split('?');
        if (currentPath.length > 0 && searchPage.length > 0 && currentPath[0] == searchPage[0]) {
            navigate(ctx.getSpaRoute(newSearchUrl));
            window.location.reload();
        } else {
            navigate(ctx.getSpaRoute(newSearchUrl));
        }
    }

    const getSearchUrl = () => {
        if (
            searchMobileFieldRef &&
            searchMobileFieldRef.current &&
            searchMobileFieldRef.current['value'] &&
            searchPageUrl != null &&
            searchPageUrl != '#'
        ) {
            let searchUrl = new URL(searchPageUrl).pathname;
            searchUrl += '?search=' + searchMobileFieldRef.current['value'];
            return searchUrl;
        }
        return new URL(searchPageUrl).pathname + '?search=';
    };

    useEffect(() => {
        setSearchPageUrl(props.domainPage?.searchPage?.value?.url ? props.domainPage?.searchPage?.value?.url : '#');
    });

    useEffect(() => {
        const currentAnchestors: string[] = [];
        api.getAncestors(props.currentPage).then((anchestors) => {
            if (anchestors && anchestors.length > 0) {
                anchestors.forEach((anchestor) => {
                    const currentId = anchestor.contentLink?.id;
                    if (currentId != null) {
                        currentAnchestors.push(currentId.toString());
                    }
                });
            }
            setCurrentAnchestors(currentAnchestors);
        });
    }, [props.currentPage]);

    function getCssClass(currentPage: Taxonomy.IContent, submenuPage: SubMenuDto): string {
        if (currentPage.contentLink.id.toString() == submenuPage.Id) {
            return 'selected';
        }
        let output = '';
        if (currentAnchestors && currentAnchestors.length > 0) {
            currentAnchestors.forEach((anchestor) => {
                if (anchestor == submenuPage.Id) {
                    output = 'selected';
                }
            });
        }
        return output;
    }

    function toggleDropDownMenu(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        const toggleElement = document.getElementById('main-menu');

        if (toggleElement.classList.contains('is-active')) {
            toggleElement.classList.remove('is-active');
            e.currentTarget.setAttribute('aria-expanded', 'false');
        } else {
            toggleElement.classList.add('is-active');
            e.currentTarget.setAttribute('aria-expanded', 'true');
        }
    }

    const [languages, setLanguages] = useState<Taxonomy.LanguageList>([]);
    useEffect(() => {
        let isCancelled: boolean = false;
        repo.getCurrentWebsite().then((x) => {
            if (isCancelled) return;
            if (!x) {
                axios.get('/api/episerver/v3.0/site').then((response: any) => {
                    setLanguages((response.data[0] as Website).languages);
                });
            } else {
                setLanguages(x.languages);
            }
        });
        return () => {
            isCancelled = true;
        };
    }, []);

    // Select language handler
    const selectLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const l = languages.filter((x) => x.name === event.currentTarget.value)[0];
        if (props.dispatch) props.dispatch({ type: 'OptiContentCloud/SetState', currentLanguage: l.name });
        const currentUrl = new URL(props.currentPage.contentLink.url);
        if (currentUrl && currentUrl.pathname) {
            repo.getByRoute(currentUrl.pathname).then((currentContent) => {
                if (currentContent.contentType.includes('StartPage')) {
                    navigate('/');
                } else if (currentContent.existingLanguages && currentContent.existingLanguages.length > 0) {
                    const currentContentInNewLanguageList: any = currentContent.existingLanguages.filter(
                        (lang) => lang.name === l.name
                    );
                    if (currentContentInNewLanguageList && currentContentInNewLanguageList.length > 0) {
                        const currentContentInNewLanguageUrl = new URL(currentContentInNewLanguageList[0].link);
                        navigate(ctx.getSpaRoute(currentContentInNewLanguageUrl.pathname));
                    }
                }
            });
        } else if (l.urlSegment) navigate(ctx.getSpaRoute(l.urlSegment));
    };

    return (
        <nav className="main-menu" id="main-menu" aria-labelledby="main-menu-title">
            <h2 id="main-menu-title" className="sr-only">
                {getLocalizationText(props.currentPage.language.name, [OldDomstol, 'MenuTitle'])}
            </h2>
            <div className="main-menu__search">
                {/* TODO: for/id/map search to new search */}
                <form
                    role="search"
                    aria-label={getLocalizationText(props.currentPage.language.name, [General, 'Search'])}
                >
                    <label htmlFor="mobile-search">
                        {getLocalizationText(props.currentPage.language.name, [OldDomstol, 'Searchlabel'])}
                    </label>
                    <input
                        onKeyPress={handleKeyPress}
                        ref={searchMobileFieldRef}
                        type="text"
                        id="mobile-search"
                        name="search"
                    />
                    <input
                        onClick={handleButtonPress}
                        type="submit"
                        className="button--search"
                        value={getLocalizationText(props.currentPage.language.name, [OldDomstol, 'QuickSearch'])}
                    />
                </form>
            </div>

            <ul className="main-menu__top-ul">
                {props.menu &&
                    props.menu.length > 0 &&
                    props.menu.map((menuitem, index) => (
                        <li className="main-menu__top-ul--li" key={'menu-' + index}>
                            <span className={'main-menu__top-ul--li-link ' + getCssClass(props.currentPage, menuitem)}>
                                <Link to={menuitem.Link} onClick={(e) => toggleDropDownMenu(e)}>
                                    {menuitem.Name}
                                </Link>
                            </span>
                        </li>
                    ))}
            </ul>

            {/* TODO: fix language selection logic */}
            {(props?.domainPage as any).cssBodyClass?.value != 'supervisoryCommittee' && (
                <form
                    method="post"
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >
                    <label className="sr-only" htmlFor="SelectedLanguage">
                        {getLocalizationText(props.currentPage.language.name, [OldDomstol, 'Choose'])}
                    </label>
                    <div className="languageSelector">
                        <select
                            onChange={(e) => selectLanguage(e)}
                            value={ctx.Language}
                            id="SelectedLanguage"
                            name="SelectedLanguage"
                        >
                            <option value="no">{norwegian_language_full_name}</option>
                            <option value="se">{samegiella_language_full_name}</option>
                            <option value="en">{english_language_full_name}</option>
                        </select>
                        <span className="languageSelector__helper">
                            <img src="/StaticContent/UI/icons/chevron.svg" alt="" />
                        </span>
                    </div>
                    <noscript>
                        <input
                            type="submit"
                            className="button--search"
                            value={getLocalizationText(props.currentPage.language.name, [OldDomstol, 'SelectButton'])}
                        />
                    </noscript>
                </form>
            )}
        </nav>
    );
};

export const ConnectedMainMenu = connect((state: State.CmsAppState) => state.OptiContentCloud || {})(MainMenu);

export const LanguageSelector = (props: MainMenuProps) => {
    const ctx = useEpiserver();
    if (ctx.isServerSideRendering()) return <MainMenu {...props} />;
    return <ConnectedMainMenu {...props} />;
};

export default LanguageSelector;
