import { getDescription } from '../../Utils';
import { Helmet } from 'react-helmet-async';

type OldHelmetAndMetadataProps = {
    data: any;
};

export const OldHelmetAndMetadata = (props: OldHelmetAndMetadataProps) => {
    const title = props.data.heading.value ? props.data.heading.value : props.data.name.toString();
    const imageUrl = props.data?.someTeaserImage?.value?.url
        ? props.data?.someTeaserImage?.value?.url
        : props.data?.mainImage?.image?.value?.url
          ? props.data?.mainImage?.image?.value?.url
          : '';

    return (
        <Helmet htmlAttributes={{ lang: props.data.language.name }}>
            <title>{title}</title>
            <meta name="pageid" content={props.data.contentLink.id.toString()} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={getDescription(props.data)} />
            <meta property="description" name="description" content={getDescription(props.data)} />
            {imageUrl && <meta property="og:image" content={imageUrl} />}
        </Helmet>
    );
};

export default OldHelmetAndMetadata;
