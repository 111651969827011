import { Link } from 'react-router-dom';
import { getLocalizationText, OldDomstol } from '../../../../utils/getLocalizationText';
import { scrollToHash } from '../../../../utils/hashHook';

export const SkipToContent = (props: { language: string }) => {
    return (
        <div id="skip-link">
            <Link tabIndex={0} to="#main-content" onClick={() => scrollToHash('main-content')}>
                {getLocalizationText(props.language, [OldDomstol, 'SkipToContent'])}
            </Link>
        </div>
    );
};

export default SkipToContent;
