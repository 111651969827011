import { getLocalizationText, OldDomstol } from '../../../../utils/getLocalizationText';
import { Taxonomy, useContentDeliveryAPI } from '@episerver/spa-core';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export const OldBreadcrumbs = (props: { currentPage: Taxonomy.IContent }) => {
    const api = useContentDeliveryAPI();
    const [ancestors, setPath] = useState<Taxonomy.IContent[]>([]);

    // get all ancestor pages from
    useEffect(() => {
        if (props.currentPage) {
            api.getAncestors(props.currentPage).then((ancestors) => {
                ancestors = ancestors
                    .filter((x) => !x.contentType.includes('StartPage') && !x.contentType.includes('SysRoot'))
                    .reverse();
                setPath(ancestors);
            });
        } else {
            setPath([]);
        }
    }, [props.currentPage]);

    if (ancestors.length < 1) return null;

    return (
        <nav className="breadcrumbs" aria-labelledby="breadcrumbs-title">
            <h2 id="breadcrumbs-title" className="sr-only">
                {getLocalizationText(props.currentPage.language.name, [OldDomstol, 'Breadcrumbs'])}
            </h2>
            <ol vocab="http://schema.org/" typeof="BreadcrumbList">
                {ancestors.map((ancestor, index) => (
                    <li property="itemListElement" typeof="ListItem" key={'breadcrumb-' + index}>
                        <Link property="item" typeof="WebPage" to={ancestor.contentLink.url}>
                            <span className="pe-1" property="name">
                                {' '}
                                {(ancestor as any).heading?.value
                                    ? (ancestor as any).heading?.value
                                    : ancestor.name}{' '}
                            </span>
                        </Link>
                        <meta property="position" content={'' + (index + 1)} />
                    </li>
                ))}

                <li>
                    <span className="pe-1">
                        {(props.currentPage as any).heading?.value
                            ? (props.currentPage as any).heading?.value
                            : props.currentPage.name}
                    </span>
                </li>
            </ol>
        </nav>
    );
};

export default OldBreadcrumbs;
