import { getLocalizationText, OldDomstol } from '../../../../utils/getLocalizationText';
import { OldDomainPageType } from '../../../../Models/Content/OldDomainPageData';
import { FacebookIcon, FlickrIcon, Icon, InstagramIcon, LinkedInIcon, XIcon } from '@norges-domstoler/dds-components';
import { Link } from 'react-router-dom';

function isOldDomainPageType(pagetype: OldDomainPageType): pagetype is OldDomainPageType {
    return pagetype.availabilityDeclaration !== undefined;
}

export const OldFooter = (props: { domainPage: OldDomainPageType }) => {
    return (
        <footer className="page-footer" id="footer">
            <div className="page-footer__row">
                <div className="page-footer__column">
                    {props.domainPage.name && <h2>{props.domainPage?.name?.toString()}</h2>}
                    <ul>
                        {props.domainPage.organizationNumber.value && (
                            <li>{props.domainPage.organizationNumber.value}</li>
                        )}
                        {props.domainPage.privacyLink?.value?.url && (
                            <li>
                                <Link to={props.domainPage.privacyLink?.value?.url} className="footerEmail">
                                    {getLocalizationText(props.domainPage.language.name, [OldDomstol, 'Privacy'])}
                                </Link>
                            </li>
                        )}
                        {isOldDomainPageType(props.domainPage) && props.domainPage.availabilityDeclaration?.value && (
                            <li>
                                <Link
                                    to={props.domainPage.availabilityDeclaration.value}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {getLocalizationText(props.domainPage.language.name, [
                                        OldDomstol,
                                        'AvailabilityDeclaration',
                                    ])}
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
                {props.domainPage.postalAddress?.value && !props.domainPage.contactPageLink?.value && (
                    <div className="page-footer__column">
                        <h2>{getLocalizationText(props.domainPage.language.name, [OldDomstol, 'Postaladdress'])}</h2>
                        <ul>
                            {props.domainPage.postalAddress?.value && (
                                <li
                                    className="address"
                                    dangerouslySetInnerHTML={{
                                        __html: props.domainPage.postalAddress?.value.replace(',', '<br />'),
                                    }}
                                />
                            )}
                        </ul>
                    </div>
                )}

                {props.domainPage.visitAddress?.value && !props.domainPage.contactPageLink?.value && (
                    <div className="page-footer__column">
                        <h2>{getLocalizationText(props.domainPage.language.name, [OldDomstol, 'Visitoraddress'])}</h2>
                        <ul>
                            {props.domainPage.visitAddress?.value && (
                                <li
                                    className="address"
                                    dangerouslySetInnerHTML={{
                                        __html: props.domainPage.visitAddress?.value.replace(',', '<br />'),
                                    }}
                                />
                            )}
                        </ul>
                    </div>
                )}

                {!props.domainPage.contactPageLink?.value ? (
                    <div className="page-footer__column">
                        <h2>{getLocalizationText(props.domainPage.language.name, [OldDomstol, 'Contact'])}</h2>
                        <ul>
                            {props.domainPage.phone?.value && (
                                <li className="footerPhone">{props.domainPage.phone?.value}</li>
                            )}
                            {props.domainPage.email?.value && (
                                <li>
                                    <a href={'mailto:' + props.domainPage.email?.value} className="footerEmail">
                                        {props.domainPage.email?.value}
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>
                ) : (
                    <div className="page-footer__column page-footer__column--stretch">
                        <h2>{getLocalizationText(props.domainPage.language.name, [OldDomstol, 'Contact'])}</h2>
                        <ul>
                            <li>
                                <Link to={props.domainPage.contactPageLink?.value?.url}>
                                    {getLocalizationText(props.domainPage.language.name, [OldDomstol, 'ContactUs'])}
                                </Link>
                            </li>
                        </ul>
                    </div>
                )}

                {(props.domainPage.twitter?.value ||
                    props.domainPage.facebook?.value ||
                    props.domainPage.linkedIn?.value ||
                    props.domainPage.instagram?.value ||
                    props.domainPage.flickr?.value) && (
                    <div className="page-footer__column">
                        <h2>{getLocalizationText(props.domainPage.language.name, [OldDomstol, 'Follow'])}</h2>
                        <ul>
                            {props.domainPage.twitter?.value && (
                                <li className="social twitterLink">
                                    <Link to={props.domainPage.twitter.value} target="_blank" rel="noreferrer">
                                        <Icon iconSize="small" icon={XIcon} />X
                                    </Link>
                                </li>
                            )}
                            {props.domainPage.facebook?.value && (
                                <li className="social facebookLink">
                                    <Link to={props.domainPage.facebook.value} target="_blank" rel="noreferrer">
                                        <Icon iconSize="small" icon={FacebookIcon} />
                                        Facebook
                                    </Link>
                                </li>
                            )}
                            {props.domainPage.instagram?.value && (
                                <li className="social instagramLink">
                                    <Link to={props.domainPage.instagram.value} target="_blank" rel="noreferrer">
                                        <Icon iconSize="small" icon={InstagramIcon} />
                                        Instagram
                                    </Link>
                                </li>
                            )}
                            {props.domainPage.linkedIn?.value && (
                                <li className="social linkedInLink">
                                    <Link to={props.domainPage.linkedIn.value} target="_blank" rel="noreferrer">
                                        <Icon iconSize="small" icon={LinkedInIcon} />
                                        LinkedIn
                                    </Link>
                                </li>
                            )}
                            {props.domainPage.flickr?.value && (
                                <li className="social flickrLink">
                                    <Link to={props.domainPage.flickr.value} target="_blank" rel="noreferrer">
                                        <Icon iconSize="small" icon={FlickrIcon} />
                                        Flickr
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </div>
                )}
            </div>
        </footer>
    );
};

export default OldFooter;
