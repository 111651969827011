import { getLocalizationText, OldDomstol } from '../../../../utils/getLocalizationText';
import { ContentDelivery, Taxonomy } from '@episerver/spa-core';
import { useEffect, useState } from 'react';
import axios from 'axios';

export const OldFeedback = (props: {
    currentPage: Taxonomy.IContent;
    layoutSettings?: ContentDelivery.StringProperty;
}) => {
    const feedback_api = '/api/episerver/v3/feedbackolddomstol';

    const [clickedButton, setClickedButton] = useState<string>('');
    const [errorSendingMail, setErrorSendingMail] = useState<boolean>(false);
    const [userInput, setUserInput] = useState<string>('');
    const [userEmail, setUserEmail] = useState<string>('');

    const [showTextFieldValidationMessage, SetShowTextFieldValidationMessage] = useState<boolean>(false);
    const [showEmailValidationMessage, SetShowEmailValidationMessage] = useState<boolean>(false);

    function toggleYesNoButton(response: string) {
        if (response) {
            setClickedButton(response);
        } else {
            setClickedButton('');
        }
    }

    function handleUserInput(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) {
        setUserInput(e.target?.value);
        if (e.target?.value != '') {
            SetShowTextFieldValidationMessage(false);
        }
    }

    function handleUserInputEmail(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) {
        setUserEmail(e.target?.value);
        if (e.target?.value != '') {
            SetShowEmailValidationMessage(false);
        }
    }

    function validEmail(email: string) {
        if (email == null || email == '') {
            return false;
        }
        const re =
            /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        return re.test(email);
    }

    function validText(text: string) {
        if (text == null || text == '') {
            return false;
        }
        return true;
    }

    function SendMail() {
        setErrorSendingMail(false);

        const isValidEmail = validEmail(userEmail);
        const isValidText = validText(userInput);

        if (isValidEmail && isValidText) {
            const body = {
                Email: userEmail,
                EmailText: userInput,
                PageId: props.currentPage?.contentLink?.id?.toString(),
            };

            axios
                .post(feedback_api, body)
                .then((rs) => {
                    setErrorSendingMail(false);
                    if (rs.data) {
                        setClickedButton('yes');
                    } else {
                        setErrorSendingMail(true);
                        setClickedButton('no');
                    }
                })
                .catch(function () {
                    setErrorSendingMail(true);
                    setClickedButton('no');
                });
        }

        SetShowEmailValidationMessage(!isValidEmail);
        SetShowTextFieldValidationMessage(!isValidText);
    }

    useEffect(() => {
        setClickedButton('');
    }, [props.currentPage.contentLink.id]);

    return (
        <>
            <div className="feedback _jsfeedback">
                <h2 className="smallHeader">
                    {getLocalizationText(props.currentPage.language.name, [OldDomstol, 'FeedbackTeaser'])}
                </h2>
                {clickedButton == '' && (
                    <div aria-hidden="false" className="initialResponse _jsFeedbackButtons">
                        <input
                            onClick={() => toggleYesNoButton('no')}
                            type="button"
                            className="_jsNegativeFeedbackButton"
                            value={getLocalizationText(props.currentPage.language.name, [
                                OldDomstol,
                                'FeedbackOptionYes',
                            ])}
                        />
                    </div>
                )}

                {clickedButton == 'no' && (
                    <div aria-hidden="true" className="_jsNegativeFeedback infoResponse" data-pageid="FeedbackPageID">
                        <form>
                            <div className="sr-only _jsFeedbackFormAlert"></div>
                            {props.layoutSettings != null && props.layoutSettings.value != '' && (
                                <>
                                    <p dangerouslySetInnerHTML={{ __html: props.layoutSettings.value }} />
                                </>
                            )}

                            <input
                                id="FeedbackPageID"
                                name="FeedbackPageID"
                                type="hidden"
                                value={props.currentPage.contentLink.id}
                            ></input>

                            <input className="hidden" id="hPot" type="text"></input>

                            <label htmlFor="FeedbackEmail">
                                {getLocalizationText(props.currentPage.language.name, [
                                    OldDomstol,
                                    'FeedbackFormEmail',
                                ])}
                            </label>

                            <input
                                onChange={(e) => handleUserInputEmail(e)}
                                type="email"
                                name="email"
                                id="FeedbackEmail"
                                className="input _jsFeedbackEmail"
                                placeholder={getLocalizationText(props.currentPage.language.name, [
                                    OldDomstol,
                                    'FeedbackFormYourEmail',
                                ])}
                            />
                            {showEmailValidationMessage && (
                                <span className="error error-email field-validation-valid field-validation-error">
                                    {getLocalizationText(props.currentPage.language.name, [
                                        OldDomstol,
                                        'FeedbackFormErrorMessagesEmail',
                                    ])}
                                </span>
                            )}

                            <span className="hiddenTxt">
                                {getLocalizationText(props.currentPage.language.name, [
                                    OldDomstol,
                                    'FeedbackFormEmailComment',
                                ])}
                            </span>

                            <label htmlFor="FeedbackComment">
                                {getLocalizationText(props.currentPage.language.name, [
                                    OldDomstol,
                                    'FeedbackFormEmailComment',
                                ])}
                            </label>
                            <textarea
                                onChange={(e) => handleUserInput(e)}
                                id="FeedbackComment"
                                name="comment"
                                cols={20}
                                rows={3}
                                className="input _jsFeedbackComment"
                                placeholder={getLocalizationText(props.currentPage.language.name, [
                                    OldDomstol,
                                    'FeedbackFormYourComment',
                                ])}
                            ></textarea>

                            {showTextFieldValidationMessage && (
                                <span className="error error-comment field-validation-valid field-validation-error">
                                    {getLocalizationText(props.currentPage.language.name, [
                                        OldDomstol,
                                        'FeedbackFormErrorMessagesComment',
                                    ])}
                                </span>
                            )}

                            <input
                                onClick={() => SendMail()}
                                type="button"
                                className="_jsNegativeFeedbackSubmit"
                                value={getLocalizationText(props.currentPage.language.name, [
                                    OldDomstol,
                                    'FeedbackFormBtn',
                                ])}
                            />
                        </form>
                    </div>
                )}

                {clickedButton == 'yes' && (
                    <div aria-hidden="true" className="infoResponse note _jsPositiveFeedback">
                        <p>{getLocalizationText(props.currentPage.language.name, [OldDomstol, 'FeedbackSuccess'])}</p>
                    </div>
                )}

                {errorSendingMail && (
                    <div
                        aria-hidden="true"
                        className="infoResponse error-sending note alert field-validation-valid field-validation-error"
                    >
                        <p>
                            {getLocalizationText(props.currentPage.language.name, [
                                OldDomstol,
                                'FeedbackFormErrorMessagesNotSent',
                            ])}
                        </p>
                    </div>
                )}

                <div aria-hidden="true" className="infoResponse success note hidden">
                    <p>
                        {getLocalizationText(props.currentPage.language.name, [OldDomstol, 'FeedbackFormConfirmation'])}
                    </p>
                </div>
            </div>
        </>
    );
};

export default OldFeedback;
